.theme-toggle-btn {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.theme-toggle-btn:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.theme-toggle-btn.dark {
    color: #fff;
}

.theme-toggle-btn.dark:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.theme-toggle-btn svg {
    display: block;
}