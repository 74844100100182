#navbar {
    padding: 30px;
}

.Up-arrow-btn {
    position: fixed;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 9999;
    font-size: 50px;
}

@media only screen and (max-width: 1023.9px) {
    .Nav-link {
        width: 100%;
        text-align: center;
    }

    .navbar-menu {
        position: absolute;
        left: auto;
        right: 0;
    }

    [data-theme="dark"] .navbar-menu {
        background-color: rgba(0, 0, 0, 0.9);
    }

    [data-theme="light"] .navbar-menu {
        background-color: rgba(255, 255, 255, 0.9);
    }
}

.logo {
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.25));
}