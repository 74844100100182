@import url('https://fonts.googleapis.com/css?family=Abel');

.App {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-family: "Abel";
}

.App-content {
  position: absolute;
  width: 100vw;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
}

.avatar {
  border-radius: 50%;
}

.hero {
  padding: 0.75rem;
}

[data-theme="dark"] .About-me {
  background-color: navy;
}

[data-theme="light"] .About-me {
  background-color: #3273dc;
}

.About-me>.container {
  padding-bottom: 150px;
}

[data-theme="dark"] .Footer {
  background-color: navy;
}

[data-theme="light"] .Footer {
  background-color: #3273dc;
}

.Footer>.container {
  padding-bottom: 25px;
}

.footer-icons>.field>a {
  margin: 10px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.Footer-logo {
  margin-bottom: 50px;
}

.Footer-button {
  border-radius: 50%;
}

.Footer-button>.icon {
  padding: 2.5px;
}

[data-theme="dark"] .Projects {
  background-color: navy;
}

[data-theme="light"] .Projects {
  background-color: #3273dc;
}

.Projects>.container {
  padding-bottom: 150px;
}

.has-text-white-muted {
  color: white;
  opacity: .70;
}

.Work-subtitle {
  padding-bottom: 65px;
}

.clients>.container>.client-grid>.columns>* {
  padding: 50px;
}

.clients {
  @media only screen and (min-width: 1025px) {
    margin-top: -150px;
  }
}

.work::after {
  content: "";
  display: block;
  height: 1px;
  background-color: gray;
  opacity: 0.5;
  margin-top: 100px;
}

.Clients-title-column {
  margin-bottom: 50px;
}