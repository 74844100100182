.Projects-container>.projects-grid>.columns>.column>.box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 300px;
    width: 400px;
    margin-bottom: 20px;
}

[data-theme="dark"] .Projects-container>.projects-grid>.columns>.column>.box {
    border: 0.1px solid rgba(255, 255, 255, 0.25);
    border-radius: 10px;
}

[data-theme="light"] .Projects-container>.projects-grid>.columns>.column>.box {
    border: 0.1px solid rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.Projects-container>.projects-grid>.columns>.column>.box>a {
    width: fit-content;
}

.Projects-container>.projects-grid>.columns {
    margin-bottom: 20px;
}

.Projects-container {
    margin-top: -200px;
}