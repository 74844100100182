.project-thumb {
    opacity: 1;
    transition:
        opacity 0.3s,
        transform 0.3s;
    position: relative;
    margin-left: 7.5%;
}

.Project-figure>figcaption {
    opacity: 0;
    transition: opacity 0.3s;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;
    padding: 50px;
}

.Project-figure>figcaption a {
    display: inline-block;
    max-height: 40px;
    overflow: hidden;
}

.Project-figure {
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

[data-theme="dark"] .Project-figure {
    background-color: navy;
}

[data-theme="light"] .Project-figure {
    background-color: #3273dc;
}

.Project-figure:hover .project-thumb {
    opacity: 0.7;
    transform: scale(1.1);
    /* Zoom in the image slightly */
}

.Project-figure:hover .project-thumb {
    opacity: 0;
    /* Make the image invisible on hover */
}

.Project-figure:hover .product-title {
    opacity: 0;
    /* Make the title invisible on hover */
}

.Project-figure:hover figcaption {
    opacity: 1;
}

.Project-figure:hover figcaption a {
    display: inline-block;
}

.product-title {
    font-size: 2em;
    font-weight: bold;
}

.viva {
    margin-bottom: 20%;
    margin-left: 50%;
}

.vivaplus {
    margin-bottom: 20%;
    margin-left: 65%;
}

.latitude {
    margin-bottom: 35%;
    margin-left: 10%;
}

.asarconnect {
    padding: 2.5%;
    font-size: 3em;
    width: fit-content;
    height: fit-content;
    background-color: white;
}

.asar {
    color: #fcc413;
}

.connect {
    color: #046bd2;
}